import { myFormsNavItem } from './myFromsNavItem';
import { productsNavItem } from './productsNavItem';
import { templatesNavItem } from './templatesNavItem';
import { integrationsNavItem } from './integrationsNavItem';
import { supportNavItem } from './supportNavItem';
import { enterpriseNavItem } from './enterpriseNavItem';
import { pricingsNavItem } from './pricingsNavItem';

export { signNavItem } from './signNavItem';
export { pdfEditorNavItem } from './pdfEditorNavItem';

export const DEFAULT_NAV_ITEMS = [
  myFormsNavItem,
  templatesNavItem,
  integrationsNavItem,
  productsNavItem,
  supportNavItem,
  enterpriseNavItem,
  pricingsNavItem
];
