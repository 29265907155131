import React, { useState } from 'react';
import {
  array, bool, func, number, object, string
} from 'prop-types';
import { DEFAULT_NAV_ITEMS, signNavItem, pdfEditorNavItem } from '@jotforminc/constants';

import NavItem from './NavItem';
import { CONTACT_SALES_AB_TEST_VARIATIONS } from '../../constants';

const NavItems = ({
  user,
  navItems,
  logHeaderAction,
  currentPath,
  isLoggedIn,
  defaultNavItems, // todo: ??
  isMobileMenuActive,
  unreadSupportAnswerCount,
  showAiAgentBanner,
  contactSalesAbTestVariation,
  isEnterprise,
  isWorkflowsReleased
}) => {
  const [openedMenu, setOpenedMenu] = useState('');
  const { CONTACT_SALES_AS_ITEM, NO_INTEGRATIONS_MENU } = CONTACT_SALES_AB_TEST_VARIATIONS;

  const selectNavItems = () => {
    if (navItems === false) return [];
    if (typeof navItems === 'undefined' || navItems?.length === 0) return DEFAULT_NAV_ITEMS;
    return navItems;
  };

  let items = [...selectNavItems()];

  // this is a temporary solution to add pdfEditor and sign to the navItems
  if (defaultNavItems.includes('pdfEditor')) {
    items.unshift(pdfEditorNavItem);
  }

  if (defaultNavItems.includes('sign')) {
    items.unshift(signNavItem);
  }

  // A/B Test: contactSalesButtonOnHeader
  if ([CONTACT_SALES_AS_ITEM, NO_INTEGRATIONS_MENU].includes(contactSalesAbTestVariation)) {
    items = items.filter(item => item.name !== 'integrations');
  }

  if (isWorkflowsReleased) {
    items = items.map(item => {
      const { name = '', menuProps = {} } = item || {};
      const isProductMenuItem = name !== 'products';
      if (name !== 'templates' && isProductMenuItem) return item;
      const { menuItems = [] } = menuProps;
      const updatedMenuItems = menuItems.map(menuItem => {
        if (menuItem.name !== 'approvalTemplates' && menuItem.name !== 'jotformApprovals') return menuItem;
        return {
          ...menuItem,
          badge: 'NEW',
          url: menuItem.url.replace('approval', 'workflow'),
          name: isProductMenuItem ? 'jotformWorkflows' : 'workflowTemplates',
          title: isProductMenuItem ? 'Jotform Workflows' : 'Workflow Templates'
        };
      });
      return {
        ...item,
        menuProps: {
          ...menuProps,
          menuItems: updatedMenuItems
        }
      };
    });
  }
  return (
    <>
      {items.map(({ name, linkProps, menuProps }) => (
        <NavItem
          key={name}
          {...linkProps}
          {...menuProps}
          user={user}
          isEnterprise={isEnterprise}
          isLoggedIn={isLoggedIn}
          openedMenu={openedMenu}
          setOpenedMenu={setOpenedMenu}
          currentPath={currentPath}
          isPricing={name === 'pricing'}
          logHeaderAction={logHeaderAction}
          showAiAgentBanner={showAiAgentBanner}
          isMobileMenuActive={isMobileMenuActive}
          unreadSupportAnswerCount={unreadSupportAnswerCount}
        />
      ))}
    </>
  );
};

NavItems.propTypes = {
  user: object.isRequired,
  navItems: array,
  logHeaderAction: func,
  currentPath: string.isRequired,
  isLoggedIn: bool,
  defaultNavItems: array,
  isMobileMenuActive: bool,
  unreadSupportAnswerCount: number,
  showAiAgentBanner: bool.isRequired,
  contactSalesAbTestVariation: string.isRequired,
  isEnterprise: bool.isRequired,
  isWorkflowsReleased: bool
};

NavItems.defaultProps = {
  navItems: DEFAULT_NAV_ITEMS,
  logHeaderAction: () => {},
  isLoggedIn: undefined,
  defaultNavItems: [],
  isMobileMenuActive: false,
  unreadSupportAnswerCount: 0,
  isWorkflowsReleased: false
};

export default NavItems;
