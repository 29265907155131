import React from 'react';
import cx from 'classnames';
import {
  array, bool, number, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { DedicatedSupportSection, ProfessionalServicesBanner } from '@jotforminc/enterprise-promotions';

const SupportMenuType = ({
  menuItems, sideMenuItems, isLoggedIn, menuTitle, sideMenuTitle, isOpen, unreadSupportAnswerCount, isEnterprise
}) => {
  const SupportMenuTypeClasses = cx('jfRHeader--nav-sub-menu jfSupport', {
    isOpen
  });
  const filteredMenuItems = menuItems.filter(({ loginRequired }) => (loginRequired ? isLoggedIn : true));

  const shouldShowSupportAnswersCount = ({ name, count }) => name === 'mySupportRequests' && count > 0 && !isEnterprise;

  return (
    <ul className={SupportMenuTypeClasses}>
      {filteredMenuItems.length > 0 && (
        <li className="jfSub-menu">
          <ul>
            <li className="jfRHeader--nav-sub-menu-text">{t(menuTitle)}</li>
            {filteredMenuItems.map(({
              name, title, url, icon, badge
            }) => (
              <li key={name} className='jfRHeader--nav-sub-menu-item'>
                <a href={url} className={shouldShowSupportAnswersCount({ name, count: unreadSupportAnswerCount }) ? 'jfRHeader--nav-sub-menu-item-badge-link' : ''}>
                  {icon}
                  {t(title)}
                  {badge && (
                    <strong className="jfRHeader--nav-menu-item-badge">
                      {t(badge)}
                    </strong>
                  )}
                  {shouldShowSupportAnswersCount({ name, count: unreadSupportAnswerCount }) && <span className="jfRHeader--nav-sub-menu-item-badge">{unreadSupportAnswerCount}</span>}
                </a>
              </li>
            ))}
          </ul>
        </li>
      )}
      {sideMenuItems.length > 0 && (
        <li className="jfSub-menu jfSupportLearn">
          <ul>
            <li className="jfRHeader--nav-sub-menu-text">{sideMenuTitle}</li>
            {sideMenuItems.map(({
              name, title, url, icon
            }) => (
              <li key={name} className='jfRHeader--nav-sub-menu-item'>
                <a href={url}>
                  {icon}
                  {t(title)}
                </a>
              </li>
            ))}
          </ul>
        </li>
      )}
      <li className="dedicated-support">
        <DedicatedSupportSection />
      </li>
      <li className="professional-services-wrapper">
        <ProfessionalServicesBanner />
      </li>
    </ul>
  );
};

SupportMenuType.propTypes = {
  menuItems: array.isRequired,
  sideMenuItems: array.isRequired,
  isLoggedIn: bool.isRequired,
  menuTitle: string.isRequired,
  sideMenuTitle: string.isRequired,
  isOpen: bool.isRequired,
  unreadSupportAnswerCount: number.isRequired,
  isEnterprise: bool.isRequired
};

export default SupportMenuType;
