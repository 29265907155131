/* eslint-disable max-len */

import { TEXTS } from './texts';
import { MENU_BADGE_TYPES } from './menuBadgeTypes';
import { MENU_TYPES } from './menuTypes';

const productsMenu = {
  name: 'products',
  menuTitle: 'PRODUCTS',
  sideMenuTitle: 'FEATURES',
  sideMenuSeeMoreTitle: TEXTS.SEE_MORE_FEATURES,
  sideMenuSeeMoreUrl: '/features/',
  menuItems: [
    {
      name: 'formBuilder',
      title: 'Form Builder',
      urlGuest: '/',
      url: '/build',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/form-builder.svg'
    },
    {
      name: 'jotformEnterprise',
      title: 'Jotform Enterprise',
      url: '/enterprise/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/enterprise.svg'
    },
    {
      name: 'jotformApps',
      title: 'Jotform Apps',
      url: '/products/apps/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/apps.svg'
    },
    {
      name: 'storeBuilder',
      title: 'Store Builder',
      url: '/products/store-builder/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/store-builder.svg?v=1.0'
    },
    {
      name: 'jotformTables',
      title: 'Jotform Tables',
      url: '/products/tables/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/tables.svg'
    },
    {
      name: 'jotformInbox',
      title: 'Jotform Inbox',
      url: '/features/inbox/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/inbox.svg'
    },
    {
      name: 'jotformMobileApp',
      title: 'Jotform Mobile App',
      url: '/products/mobile-forms/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/mobile-app.svg'
    },
    {
      name: 'jotformApprovals',
      title: 'Jotform Approvals',
      url: '/products/approvals/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/approvals.svg'
    },
    {
      name: 'reportBuilder',
      title: 'Report Builder',
      url: '/products/report-builder/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/report-builder.svg'
    },
    {
      name: 'smartPDFforms',
      title: 'Smart PDF Forms',
      url: '/products/smart-pdf-forms/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/smart-pdf-forms.svg'
    },
    {
      name: 'pdfEditor',
      title: 'PDF Editor',
      url: '/products/pdf-editor/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/pdf-editor.svg'
    },
    {
      name: 'jotformSign',
      title: 'Jotform Sign',
      url: '/products/sign/',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/sign.svg'
    },
    {
      name: 'jotformSalesforce',
      title: 'Jotform for Salesforce',
      url: 'https://link.jotform.com/pQYiDWJG55?utm_source=jotform.com&utm_campaign=salesforce2023&utm_medium=header&utm_content=cta_salesforce&campaign=header&adgroup=jotform.com&creative=cta_salesforce',
      icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/products/salesforce.svg'
    }
  ],
  sideMenuItems: [
    {
      name: 'jotformTeams',
      title: 'Jotform Teams',
      url: '/teams/'
    },
    {
      name: 'enterpriseMobile',
      title: 'Enterprise Mobile',
      url: '/enterprise/mobile-app/',
      badge: {
        text: 'NEW',
        type: MENU_BADGE_TYPES.success
      }
    },
    {
      name: 'prefillForms',
      title: 'Prefill Forms',
      url: '/prefill-forms/whats-new/'
    },
    {
      name: 'hipaaForms',
      title: 'HIPAA Forms',
      url: '/hipaa/'
    },
    {
      name: 'secureForms',
      title: 'Secure Forms',
      url: '/security/'
    },
    {
      name: 'assignForm',
      title: 'Assign Forms',
      url: '/features/assign-forms/'
    },
    {
      name: 'onlinePayments',
      title: 'Online Payments',
      url: '/online-payments/'
    },
    {
      name: 'widgets',
      title: 'Widgets',
      url: '/widgets/'
    }
  ]
};

export const productsNavItem = {
  name: 'products',
  menuProps: productsMenu,
  linkProps: {
    renderAs: 'button',
    title: 'Products',
    menuType: MENU_TYPES.PRODUCTS
  }
};
