import React from 'react';
import { oneOf } from 'prop-types';
import { t } from '@jotforminc/translation';
import { BADGE_TYPES, TEXTS } from '../../constants';
import HipaaSvg from '../../assets/svg/hipaa.svg';
import GovernmentSvg from '../../assets/svg/government.svg';

const Badge = ({ type }) => {
  return (
    <div className='jfRHeader--badge'>
      {type === BADGE_TYPES.GOVERNMENT ? <GovernmentSvg className="jfRHeader--badge-logo" /> : <HipaaSvg className="jfRHeader--badge-logo" />}
      <span className='jfRHeader--badge-text locale'>
        {type === BADGE_TYPES.GOVERNMENT ? t(TEXTS.GOVERNMENT) : t(TEXTS.HIPAA_COMPLIANCE)}
      </span>
    </div>
  );
};

Badge.propTypes = {
  type: oneOf(Object.values(BADGE_TYPES)).isRequired
};

export default Badge;
