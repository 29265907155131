import { MENU_TYPES } from './menuTypes';

const signMenu = {
  name: 'sign',
  menuTitle: '',
  menuItems: [
    {
      id: 0,
      name: 'other',
      title: '',
      subMenuItems: [
        {
          name: 'signBenefits',
          title: 'Benefits',
          url: '/products/sign/'
        },
        {
          name: 'signFeatures',
          title: 'Features',
          url: '/products/sign/features/'
        },
        {
          name: 'signTemplates',
          title: 'Templates',
          url: '/pdf-templates/e-sign/'
        },
        {
          name: 'signIntegrations',
          title: 'Integrations',
          url: '/integrations/e-sign/'
        },
        {
          name: 'signPricing',
          title: 'Pricing',
          url: '/products/sign/pricing/'
        }
      ]
    },
    {
      id: 1,
      name: 'explore',
      title: 'Explore',
      subMenuItems: [
        {
          name: 'signStory',
          title: 'Story',
          url: '/products/sign/introducing-jotform-sign/'
        },
        {
          name: 'signHelpGuide',
          title: 'Help Guide',
          url: '/help/guide-jotform-sign/'
        },
        {
          name: 'legalityGuide',
          title: 'Legality Guide',
          url: '/products/sign/e-signature-legality/'
        },
        {
          name: 'signFaq',
          title: 'FAQ',
          url: '/products/sign/faq/'
        },
        {
          name: 'signatureGenerator',
          title: 'Signature Creator',
          url: '/products/sign/signature-generator/'
        }
      ]
    },
    {
      id: 2,
      name: 'solutions',
      title: 'Solutions',
      seeMore: {
        title: 'See all solutions',
        url: '/products/sign/solutions/'
      },
      subMenuItems: [
        {
          name: 'healthcare',
          title: 'Healthcare',
          url:
            '/products/sign/solutions/electronic-signature-for-healthcare/'
        },
        {
          name: 'education',
          title: 'Education',
          url:
            '/products/sign/solutions/electronic-signature-for-higher-education/'
        },
        {
          name: 'realEstate',
          title: 'Real Estate',
          url:
            '/products/sign/solutions/electronic-signature-for-real-estate/'
        },
        {
          name: 'nonprofit',
          title: 'Nonprofit',
          url:
            '/products/sign/solutions/electronic-signature-for-nonprofit/'
        },
        {
          name: 'government',
          title: 'Government',
          url:
            '/products/sign/solutions/electronic-signature-for-government/'
        }
      ]
    }
  ]
};

export const signNavItem = {
  name: 'sign',
  menuProps: signMenu,
  linkProps: {
    renderAs: 'button',
    title: 'Jotform Sign',
    icon: 'https://cdn01.jotfor.ms/assets/img/jfHeader/v2/sign-icon.svg',
    menuType: MENU_TYPES.SIGN
  }
};
