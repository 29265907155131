import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { array, bool, string } from 'prop-types';

import { t } from '@jotforminc/translation';

import { isSamePath } from '../../utils';

const DefaultMenuType = ({
  menuItems,
  isOpen,
  currentPath,
  isMobileMenuActive
}) => {
  const [isCurrent, setIsCurrent] = useState(false);

  useEffect(() => {
    const isPathCurrent = menuItems.some(({ url }) => isSamePath(url, currentPath));
    setIsCurrent(isPathCurrent);
  }, [currentPath, menuItems]);

  const defaultMenuTypeClasses = cx('jfRHeader--nav-sub-menu jfSubMenu', {
    isOpen: isOpen || (isCurrent && isMobileMenuActive)
  });

  return (
    <>
      {menuItems.length > 0 && (
        <ul className={defaultMenuTypeClasses}>
          {menuItems.map(({
            name, title, url, target, badge
          }) => (
            <li key={name} className='jfRHeader--nav-sub-menu-item'>
              <a href={url} target={target} className={cx('locale', { isCurrent: isSamePath(url, currentPath) })}>
                {t(title)}
                {badge && (
                  <strong className="jfRHeader--nav-menu-item-badge">
                    {t(badge)}
                  </strong>
                )}
              </a>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

DefaultMenuType.propTypes = {
  menuItems: array.isRequired,
  isOpen: bool.isRequired,
  currentPath: string.isRequired,
  isMobileMenuActive: bool
};

DefaultMenuType.defaultProps = {
  isMobileMenuActive: false
};

export default DefaultMenuType;
