export const MENU_BADGE_TYPES = {
  success: 'success',
  warning: 'warning',
  danger: 'danger'
};

export const THEME_MAP = {
  LIGHT: 'light',
  DARK: 'dark',
  GRAY: 'gray'
};

export const THEME_CLASSNAME_MAP = {
  [THEME_MAP.DARK]: 'isDark',
  [THEME_MAP.LIGHT]: 'isLight',
  [THEME_MAP.GRAY]: 'isGray'
};

export const USER_TYPES = {
  USER: 'USER',
  FORM_USER: 'FORM_USER'
};

export const MENU_TYPES = {
  TEMPLATES: 'templates',
  INTEGRATIONS: 'integrations',
  PRODUCTS: 'products',
  SUPPORT: 'support',
  SUBMENU: 'submenu',
  SIGN: 'sign',
  PDF_EDITOR: 'pdfEditor'
};

// A/B Test: contactSalesButtonOnHeader
export const CONTACT_SALES_AB_TEST_VARIATIONS = {
  CONTROL_VARIANT: '28511',
  CONTACT_SALES_AS_CTA: '28521',
  CONTACT_SALES_AS_ITEM: '28531',
  NO_INTEGRATIONS_MENU: '28541'
};

export const BADGE_TYPES = {
  GOVERNMENT: 'government',
  HIPAA: 'hipaa'
};
